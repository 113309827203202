import { Divider, Form, Modal, Spin } from "antd";
import { KIT_FRAME_MODULE_KEY } from "./actions";
import GenericTable from "src/components/common/Table/GenericTable";
import TableFilter from "src/components/common/TableFilter/TableFilter";
import { PlusOutlined } from "@ant-design/icons";
import Breadcrumb from "src/components/common/Breadcrumb/Breadcrumb";
import Button from "src/components/common/Button/Button";
import { useCallback, useEffect, useState } from "react";
import filterColumns from "./filterColumns";
import { DATE_FORMAT } from "src/util/columns";
import dayjs from "dayjs";
import AddFrame from "./AddFrame";
import { useNavigate, useLocation } from "react-router-dom";
import KitDetailForm from "./KitDetailForm";
import {
  showErrorNotification,
  showSuccessNotification,
} from "src/util/notifications";
import { saveKit } from "./sagas";
import { useMsal } from "@azure/msal-react";
import { fetchVendorList } from "src/components/vendor/vendor-sagas";

function NewStyleKit() {
  const history = useNavigate();
  const [form] = Form.useForm();
  const { accounts }: any = useMsal();
  const [newFrameData, setNewFrameData] = useState<any>([]);
  const [open, setOpen] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  let memoizeDate = dayjs().format(DATE_FORMAT);
  const [kitDate, setKitDate] = useState<any>(memoizeDate);
  const location = useLocation();
  const kitData: any = location.state;
  const [frameData, setFrameData] = useState(kitData?.Frames || []);
  const [cancelModal, setCancelModal] = useState(false);
  const [saveModal, setSaveModal] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState<any>();
  const [collection, setCollection] = useState<any>([]);
  const [shippingDate, setShippingDate] = useState<any>();
  const [collectionDesc, setCollectionDesc] = useState<any>([]);
  const [vendorDesc, setVendorDesc] = useState<any>([]);
  const [vendor, setVendor] = useState<any>([]);

  useEffect(() => {
    fetchVendorList().then((res) => {
      const sortedVendor = res?.sort((a: any, b: any) => {
        return a.VendorName.localeCompare(b.VendorName, "en", {
          numeric: true,
        });
      });
      setVendor(sortedVendor);
    });
  }, []);

  const addDrawer = (e: React.MouseEvent<HTMLButtonElement>) => {
    const fieldData = form.getFieldsValue();
    let selectedVendor = vendor
      .map((ven: any) => {
        if (fieldData.Vendor == ven.ID) return ven.VendorName;
      })
      .filter(function (element: any) {
        return element !== undefined;
      });
    if (selectedVendor.length > 0 && fieldData.Collection.length > 0) {
      setVendorDesc(selectedVendor);
      setCollectionDesc(fieldData.Collection);
      setVisible(true);
    } else {
      showErrorNotification(
        "Please select vendor and collection before adding frames."
      );
    }
  };

  const deleteModal = (record: any) => {
    setOpen(true);
    setDeleteData(record?.UPC);
  };
  useEffect(() => {
    if (kitData) {
      form.setFieldsValue({
        KitName:
          kitData?.actionType === "Clone"
            ? "CLONE " + kitData?.KitName
            : kitData?.KitName,
        Vendor: kitData?.VendorID,
        Collection: kitData?.Collection?.split(","),
        Date: dayjs(kitData?.ModifiedDate),
        Status: kitData?.Status,
        shippingDate: kitData?.PlannedShipDate
          ? dayjs(kitData?.PlannedShipDate)
          : "",
      });
    }
    setCollectionDesc(kitData?.Collection);
    setVendorDesc(kitData?.Vendor);
    setSelectedVendor(kitData?.VendorID);
  }, [kitData]);

  let filterValues = filterColumns(deleteModal, frameData);

  const handleCancel = () => {
    setVisible(false);
    setOpen(false);
    form.resetFields();
    history("/kitMaintenance");
  };
  const handleSave = async () => {
    try {
      const row = (await form.validateFields()) as any;
      setSaveModal(true);
    } catch (error: any) {
      let errMessage = "";
      if (error?.errorFields?.[0]?.errors) {
        errMessage = error.errorFields[0].errors[0];
        showErrorNotification(errMessage);
      }
    }
  };

  const onSave = async () => {
    setConfirmLoading(true);
    try {
      const fieldData = form.getFieldsValue();
      let frameRecords: any = [];
      let filterRecords: any = [];
      let selectedCollection = collection
        .map((col: any) => {
          if (fieldData.Collection.includes(col.Description)) return col.ID;
        })
        .filter(function (element: any) {
          return element !== undefined;
        });
      if (kitData?.actionType === "Clone") {
        frameData?.map((item: any) => {
          const found = filterRecords.some((ele: any) => ele.UPC === item.UPC);
          if (!found) {
            filterRecords.push({ UPC: item.UPC, type: "add" });
          }
        });
        newFrameData.map((item: any, index: any) => {
          const found = filterRecords.some((ele: any) => ele.UPC === item.UPC);
          if (item.type === "delete") {
            filterRecords = filterRecords.filter(
              (ele: any) => ele.UPC !== item.UPC
            );
          } else if (item.type === "add" && !found) {
            filterRecords.push(item);
          }
        });
        frameRecords = filterRecords;
      } else {
        frameRecords = newFrameData;
      }
      const kitDetails = {
        KitId:
          kitData && kitData?.actionType !== "Clone" ? kitData?.KitId : null,
        KitName: fieldData.KitName,
        VendorID: fieldData.Vendor,
        CollectionID:
          kitData?.Collection === fieldData.Collection.join(",")
            ? kitData?.CollectionID
            : selectedCollection.join(","),
        ModifiedDate:
          fieldData?.Date && fieldData?.Date !== "Invalid Date"
            ? fieldData.Date
            : memoizeDate,
        Status: fieldData.Status === false ? false : true,
        Frames: frameRecords,
        CreatedBy: accounts[0]?.username,
        PlannedShipDate:
          fieldData?.shippingDate !== "Invalid Date"
            ? fieldData?.shippingDate
            : null,
      };
      await saveKit(kitDetails).then((res: any) => {
        if (res) {
          showSuccessNotification(res);
          handleCancel();
        }
      });
    } catch (error: any) {
      showErrorNotification(error?.response?.data?.error?.messages[0]);
    }
    setConfirmLoading(false);
  };

  const handleDelete = () => {
    const found = newFrameData.some((item: any) => item.UPC === deleteData);
    if (!found) {
      newFrameData.push({ UPC: deleteData, type: "delete" });
      setNewFrameData([...newFrameData]);
    } else {
      const pos = newFrameData.map((e: any) => e.UPC).indexOf(deleteData);
      newFrameData.splice(pos, 1);
      setNewFrameData([...newFrameData]);
    }
    const framePos = frameData.map((e: any) => e.UPC).indexOf(deleteData);
    frameData.splice(framePos, 1);
    setFrameData([...frameData]);
    setOpen(false);
  };

  return (
    <>
      <Spin spinning={confirmLoading}>
        <Divider />
        <Breadcrumb>
          <span onClick={() => history("/kitMaintenance")}>
            Kit Maintenance
          </span>
          <span>New Kit Maintenance</span>
        </Breadcrumb>
        <TableFilter>
          <div className="form align-items-end flex"></div>
          <div className="d-flex justify-content-end">
            <Button
              size="middle"
              icon={<PlusOutlined />}
              style={{ marginRight: "10px" }}
              onClick={addDrawer}
              ant-click-animating-without-extra-node="true"
            >
              Add Frame
            </Button>
            <Button
              size="middle"
              style={{ marginRight: "10px" }}
              onClick={handleSave}
              ant-click-animating-without-extra-node="true"
            >
              Save
            </Button>
            <Button
              size="middle"
              style={{ marginRight: "10px" }}
              onClick={() => setCancelModal(true)}
              ant-click-animating-without-extra-node="true"
              danger={true}
            >
              Cancel
            </Button>
          </div>
        </TableFilter>
        <KitDetailForm
          form={form}
          kitDate={kitDate}
          setKitDate={setKitDate}
          selectedVendor={selectedVendor}
          setSelectedVendor={setSelectedVendor}
          setCollection={setCollection}
          collection={collection}
          shippingDate={shippingDate}
          setShippingDate={setShippingDate}
          memoizeDate={memoizeDate}
          setVendor={setVendor}
          vendor={vendor}
        />
        <GenericTable
          rowkey="UPC"
          columns={filterValues.frameColumns}
          moduleKey={KIT_FRAME_MODULE_KEY}
          dataSource={frameData}
          loading={confirmLoading}
          xScroll={1000}
          yScroll={"calc(90vh - 17.5em)"}
          pagination={{
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ["10", "50", "100"],
          }}
        />
        <AddFrame
          visible={visible}
          setVisibleStatus={setVisible}
          frameData={frameData}
          setFrameData={setFrameData}
          newFrameData={newFrameData}
          setNewFrameData={setNewFrameData}
          accounts={accounts}
          vendor={vendorDesc}
          collection={collectionDesc}
        />
        <Modal
          title="Delete Frame Confirmation"
          okText="Ok"
          centered
          confirmLoading={confirmLoading}
          style={{ top: 20 }}
          open={open}
          onOk={handleDelete}
          onCancel={() => setOpen(false)}
        >
          <span>Are you sure you wish to remove this frame from the kit?</span>
        </Modal>
        <Modal
          title="Cancel Confirmation"
          okText="Ok"
          centered
          confirmLoading={confirmLoading}
          style={{ top: 20 }}
          open={cancelModal}
          onOk={handleCancel}
          onCancel={() => setCancelModal(false)}
        >
          <span>Are you sure you wish to cancel this kit?</span>
        </Modal>
        <Modal
          title="Save Confirmation"
          okText="Ok"
          centered
          confirmLoading={confirmLoading}
          style={{ top: 20 }}
          open={saveModal}
          onOk={onSave}
          onCancel={() => setSaveModal(false)}
        >
          <span>Are you sure you wish to save this kit?</span>
        </Modal>
      </Spin>
    </>
  );
}
export default NewStyleKit;
