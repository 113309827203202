import {
  Card,
  Checkbox,
  DatePicker,
  DatePickerProps,
  Descriptions,
  Drawer,
  Form,
  Input,
  Radio,
  Space,
  Spin,
} from "antd";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../util/notifications";
import { useEffect, useState } from "react";
import { getPreferencesData, updateAccessoryReplenishmentItems } from "./sagas";
import Button from "../common/Button/Button";
import { replenishmentType } from "src/util/utils";
import dayjs from "dayjs";
import { DATE_TIME_FORMAT_REPLENISHMENT } from "src/util/columns";

interface EditPreferencesType {
  showEdit: any;
  loading?: boolean;
  modalData: any;
  onLoad: any;
  accounts: any;
}

const EditPreferences = ({
  showEdit,
  loading = false,
  modalData,
  onLoad,
  accounts,
}: EditPreferencesType) => {
  const [form] = Form.useForm();
  const [rTypeValue, setRTypeValue] = useState("");
  let memoizeDate = dayjs().format(DATE_TIME_FORMAT_REPLENISHMENT);
  const [lastReplenishmentDate, setLastReplenishmentDate] = useState<any>();
  useEffect(() => {
    setRTypeValue(modalData?.[0]?.ReplenishmentType);
    const lastDate =
      modalData?.[0]?.LastReplenishmentDate &&
      modalData?.[0]?.LastReplenishmentDate !== "Invalid Date"
        ? dayjs(modalData?.[0]?.LastReplenishmentDate).format(
            DATE_TIME_FORMAT_REPLENISHMENT
          )
        : memoizeDate;
    setLastReplenishmentDate(lastDate);
  }, [modalData]);
  form.setFieldsValue({
    ReplenishThreshold: modalData?.[0]?.ReplenishThreshold,
    OrderQuantity: modalData?.[0]?.OrderQuantity,
    ReplenishmentType: rTypeValue,
    IsStockedAtDC: modalData?.[0]?.IsStockedAtDC,
    IsConsiderBackorder: modalData?.[0]?.IsConsiderBackorder
      ? modalData?.[0]?.IsConsiderBackorder
      : false,
  });
  let itemName: any;
  let offices: any;
  modalData?.map((item: any, index: any) => {
    if (index === 0) {
      itemName = item.ItemName;
      offices = item.LocationID;
    } else {
      itemName = itemName + ", " + item.ItemName;
      offices = offices + ", " + item.LocationID;
    }
  });
  const handleOk = async () => {
    const fields = form.getFieldsValue();
    let submitData: any = {
      IdList: modalData?.map((row: any) => row.Id),
      ...fields,
      Active: true,
      CreatedBy: accounts?.[0]?.username,
    };
    if (rTypeValue === "Sales Based") {
      submitData.LastReplenishmentDate = lastReplenishmentDate;
    } else {
      submitData.LastReplenishmentDate = "";
    }
    try {
      const row = (await form.validateFields()) as any;
      const res = await updateAccessoryReplenishmentItems(submitData);
      if (res) {
        showSuccessNotification(res);
        onLoad();
      } else {
        showErrorNotification(res);
      }
    } catch (error: any) {
      let errMessage = "";
      if (error?.errorFields?.[0]?.errors) {
        errMessage = error.errorFields[0].errors[0];
      } else if (error?.response?.data) {
        errMessage = error?.response?.data;
        showErrorNotification(errMessage);
      }
    }
  };
  return (
    <>
      {" "}
      <Drawer
        title="Edit Preferences"
        placement="right"
        open={showEdit}
        onClose={onLoad}
        width={600}
        closable={false}
        className="dc-drawer-panel"
        forceRender={true}
        extra={
          <Space>
            <Button danger onClick={onLoad}>
              Cancel
            </Button>
            <Button onClick={handleOk}>Update</Button>
          </Space>
        }
      >
        <Spin spinning={loading}>
          <div>
            <Form
              form={form}
              initialValues={{ remember: false }}
              autoComplete="off"
              className="add-picker-form"
              layout="vertical"
              style={{
                backgroundColor: "#f3f3f3",
                borderRadius: "16px",
                marginRight: "24px",
                boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",
              }}
            >
              {" "}
              <Card>
                {" "}
                <Descriptions column={1}>
                  {/* <Descriptions.Item label="Product Category">
                    {modalData}
                  </Descriptions.Item> */}
                  <Descriptions.Item label="Product">
                    {itemName}
                  </Descriptions.Item>
                  <Descriptions.Item label="Offices">
                    {offices}
                  </Descriptions.Item>
                </Descriptions>
                <div>
                  <Form.Item
                    label="Replenishment Type"
                    name="ReplenishmentType"
                    className="label-text area-field"
                  >
                    <Radio.Group
                      options={replenishmentType}
                      defaultValue={rTypeValue}
                      value={rTypeValue}
                      optionType="button"
                      buttonStyle="solid"
                      onChange={(e) => setRTypeValue(e.target.value)}
                      style={{ background: "#003366", color: "#fff" }}
                    />
                  </Form.Item>
                </div>
                {rTypeValue && rTypeValue === "Sales Based" && (
                  <div>
                    <Form.Item
                      label="Last Replenishment Date"
                      name="LastReplenishmentDate"
                      className="label-text area-field"
                    >
                      {" "}
                      <DatePicker
                        showTime={{ format: "hh:mm" }}
                        format={DATE_TIME_FORMAT_REPLENISHMENT}
                        value={
                          lastReplenishmentDate && dayjs(lastReplenishmentDate)
                        }
                        disabledDate={(d: any) => !d || d.isAfter(dayjs())}
                        placeholder="Last Replenishment Date"
                        onChange={(value: any) =>
                          setLastReplenishmentDate(
                            dayjs(value).format(DATE_TIME_FORMAT_REPLENISHMENT)
                          )
                        }
                      />
                    </Form.Item>
                  </div>
                )}
                <div>
                  <Form.Item
                    label="Order Quantity"
                    name="OrderQuantity"
                    className="label-text input-field"
                    rules={[
                      { required: true, message: "Order Quantity is required" },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (parseInt(value) < 0) {
                            return Promise.reject(
                              "Please ensure order quantity is greater than zero"
                            );
                          } else {
                            return Promise.resolve();
                          }
                        },
                      }),
                    ]}
                  >
                    <Input name="OrderQuantity" />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    label="Trigger Quntity"
                    name="ReplenishThreshold"
                    className="label-text input-field"
                    rules={[
                      {
                        required: true,
                        message: "Trigger Quntity is required",
                      },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (parseInt(value) < 0) {
                            return Promise.reject(
                              "Please ensure order quantity is greater than zero"
                            );
                          } else {
                            return Promise.resolve();
                          }
                        },
                      }),
                    ]}
                  >
                    <Input name="ReplenishThreshold" />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    name="IsStockedAtDC"
                    className="label-text input-field"
                    valuePropName="checked"
                  >
                    <Checkbox name="IsStockedAtDC">
                      Transferred From DC
                    </Checkbox>
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    name="IsConsiderBackorder"
                    className="label-text input-field"
                    valuePropName="checked"
                  >
                    <Checkbox name="IsConsiderBackorder" value={true}>
                      Consider Backorder
                    </Checkbox>
                  </Form.Item>
                </div>
              </Card>
            </Form>
          </div>
        </Spin>
      </Drawer>
    </>
  );
};

export default EditPreferences;
