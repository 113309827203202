import { Divider, Form, Input, Modal, Popconfirm, Spin } from "antd";
import React, { useEffect, useState } from "react";
import GenericTable from "../common/Table/GenericTable";
import { PENDING_ORDER_MODULE_KEY } from "./actions";
import { PENDING_ORDER_COLUMN } from "../../util/columns";
import { CloseOutlined } from "@ant-design/icons";
import Button from "../common/Button/Button";
import { insertAt } from "../../util/utils";
import handleCancelOrder from "./actionMethods";
import Breadcrumb from "../common/Breadcrumb/Breadcrumb";
import TableFilter from "../common/TableFilter/TableFilter";
import { fetchPendingOrderItems } from "./sagas";
import { showErrorNotification } from "../../util/notifications";
import { getUserName } from "../common/UserDetails";
import useRolePermission from "src/hooks/useRolePermission";

function PendingOrder() {
  const userName = getUserName();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [pendingOrders, setPendingOrders] = useState<any>([]);
  const [selectedRow, setSelectedRow] = useState<any>([]);
  const [open, setOpen] = useState(false);
  const { hasPermission } = useRolePermission();
  const closeModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(true);
  };

  useEffect(() => {
    handleData();
  }, []);

  const handleData = async () => {
    let data: any = {
      UserName: userName,
      offset: 0,
      limit: 5000,
    };
    const pOrders = fetchPendingOrderItems(data);
    pOrders
      .then((res) => {
        setPendingOrders(res);
      })
      .catch((error) => {
        showErrorNotification(error.response.data.error.messages[0]);
      });
  };
  const handleCancel = () => {
    setPendingOrders([]);
    handleData();
    setOpen(false);
    setSelectedRow([]);
  };
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setSelectedRow(selectedRows);
    },
    getCheckboxProps: (record: any) => {
      //return { disabled: "" }
    },
    clearSelection: () => {
      setSelectedRow([]);
    },
    selectedRowKeys: selectedRow.map((row: any) => row.ID),
    hideSelectAll: true,
  };
  const actionColumn = {
    title: "action",
    dataIndex: "operation",
    align: "center",

    width: 100,
    render: (_: any, record: any) =>
      pendingOrders.length >= 1 ? (
        <Popconfirm
          title="Sure to cancel this order?"
          onConfirm={() =>
            handleCancelOrder(
              userName,
              setConfirmLoading,
              handleCancel,
              [],
              record.ID,
              record.ItemID
            )
          }
          placement="topRight"
          okText="Yes"
          cancelText="No"
          icon={""}
        >
          <Button
            icon={<CloseOutlined />}
            danger={true}
            children={undefined}
          ></Button>
        </Popconfirm>
      ) : null,
  };

  let columns = insertAt(PENDING_ORDER_COLUMN, 12, 0, actionColumn);

  return (
    <>
      <Spin spinning={confirmLoading}>
        <Divider />
        <Breadcrumb>
          <span>Vendor Backorders</span>
        </Breadcrumb>
        <TableFilter>
          <div className="form align-items-end flex"></div>
          <div className="d-flex justify-content-end">
            <div>
              {hasPermission("pendingbackorder_cancel") && (
                <Button
                  size="middle"
                  onClick={closeModal}
                  danger={true}
                  style={{ marginRight: "10px" }}
                  disabled={!selectedRow.length}
                  ant-click-animating-without-extra-node="true"
                >
                  Cancel Orders
                </Button>
              )}
            </div>
          </div>
        </TableFilter>
        <GenericTable
          rowkey="ID"
          columns={columns}
          dataSource={pendingOrders}
          rowSelection={rowSelection}
          hideTotal={true}
          moduleKey={PENDING_ORDER_MODULE_KEY}
          xScroll={1000}
          yScroll={"calc(90vh - 17.5em)"}
        />
      </Spin>
      <Modal
        title="Cancel Order Confirmation"
        okText="Ok"
        centered
        confirmLoading={confirmLoading}
        style={{ top: 20 }}
        open={open}
        onOk={() =>
          handleCancelOrder(
            userName,
            setConfirmLoading,
            handleCancel,
            selectedRow
          )
        }
        onCancel={handleCancel}
      >
        <span>Are you sure you want to cancel pending back orders?</span>
      </Modal>
    </>
  );
}

export default PendingOrder;
