import { useState } from "react";
import styled from "styled-components";
import { Drawer, Space, Descriptions, Image, Select } from "antd";
import { ProfileButton } from "./Profile";
import { CloseIcon } from "../../common/icons";
import { MENU_PERMISSIONS } from "src/util/constants/permissions.constants";
import { useNavigate } from "react-router-dom";

const ProfilePanel = ({ user, onSignOut }: any) => {
  const navigate = useNavigate();
  const { Option } = Select;
  const [open, setOpen] = useState<boolean>(false);
  const updatedRole = localStorage.getItem("updatedRole");
  const userRole = [
    "Admin",
    "DC Staff",
    "Technical",
    "Product Staff",
    "IT Admin",
  ];
  const roleAssociations = Object.keys(MENU_PERMISSIONS)?.reduce(
    (acc: any, role: string, index: number) => {
      acc[role] = userRole[index];
      return acc;
    },
    {}
  );

  let role: any = [];
  user?.idTokenClaims?.roles?.map((item: any) => {
    role.push(item.charAt(0).toUpperCase() + item.slice(1).toLowerCase());
  });
  role = updatedRole ? roleAssociations[updatedRole] : role.join(",");

  const handleUserRole = (event: any) => {
    localStorage.setItem("updatedRole", event);
    navigate("/dashboard");
    window.location.reload();
  };
  return (
    <>
      <ProfileButton
        type="primary"
        shape="circle"
        icon={<ProfileIcon src={"./icons/avatar.svg"} alt="avatar" />}
        onClick={() => setOpen(true)}
      />
      <StyledDrawer
        open={open}
        closable={false}
        onClose={() => {
          setOpen(false);
        }}
        title={user?.name || ""}
        extra={
          <div>
            <Space>
              <CloseIcon onClick={() => setOpen(false)} />
            </Space>
          </div>
        }
      >
        <Descriptions column={1}>
          <Descriptions.Item label="Email">{user.username}</Descriptions.Item>
          <Descriptions.Item label="Role">{role}</Descriptions.Item>
          {!window.location.hostname.includes("dcapp.myeyedr.com") && (
            <Descriptions.Item label="Change Role">
              <Select
                style={{ width: "100%" }}
                placeholder="Select role"
                size="small"
                onChange={handleUserRole}
              >
                {Object.keys(roleAssociations)?.map((roleKey: any) => (
                  <Option key={roleKey} value={roleKey}>
                    {roleAssociations[roleKey]}
                  </Option>
                ))}
              </Select>
            </Descriptions.Item>
          )}
        </Descriptions>
      </StyledDrawer>
    </>
  );
};

export default ProfilePanel;

const StyledDrawer = styled(Drawer)`
  & .ant-drawer-body {
    padding: 25px;
  }

  & .ant-list-item {
    display: flex;
    font-size: 12px;
    align-items: center;
  }
`;

export const ProfileIcon = styled.img`
  width: 12px;
  height: 24px;
  vertical-align: top;
`;
